// import AnimatedBackground from "./components/AnimatedBackground";
// import { useNavigate } from "react-router-dom";
// import { MessageCircle, CheckCircle } from 'lucide-react';

// const CEOMessage = () => {
//   const navigate = useNavigate();

//   return (
//     <div className="relative min-h-screen">
//       <AnimatedBackground />
//       <div className="relative py-4 px-6 md:px-12">
//         <div className="max-w-7xl mx-auto">
//           {/* Message Card */}
//           <div className="bg-black/20 backdrop-blur-sm rounded-3xl border border-[#00D05E]/30 p-6 md:p-8 text-white">
//             {/* Header */}
//             <h1 className="font-['Impact'] text-4xl md:text-5xl text-[#00D05E] mb-4">
//               Welcome to our AI Demo Portal!
//             </h1>
            
//             {/* Main Message */}
//             <p className="text-[#ACACAC] leading-relaxed mb-8">
//               We hope you enjoy using this demo as much as we have enjoyed making it for you. Please do remember that while this is a totally live and developed product, we have only included a selection of causes and sub causes for demonstration. When a new client comes on board with us, we will partner to provide a bespoke and comprehensive journey including everything you need.
//             </p>

//             <p className="text-[#F93DCD] text-xl font-semibold mb-4">
//               We are very excited about the opportunities our new AI modules create:
//             </p>

//             {/* Benefits Section */}
//             <div className="space-y-4 mb-6">
//               <div className="flex gap-4">
//                 <MessageCircle className="text-[#00D05E] shrink-0 mt-1" size={24} />
//                 <p className="text-[#ACACAC] leading-relaxed">
//                   The <span className="text-white">Guided Free Text module</span> allows customers to describe what happened in their own words, while being expertly guided by the AI Assistant to include all the relevant information in their description. This streamlines the journey, avoiding the need for lots of confusing questions and greatly enhancing the accuracy of the end result ('Other' outcomes are reduced by 98%).
//                 </p>
//               </div>
              
//               <div className="flex gap-4">
//                 <CheckCircle className="text-[#00D05E] shrink-0 mt-1" size={24} />
//                 <p className="text-[#ACACAC] leading-relaxed">
//                   The <span className="text-white">Cause and Sub-Cause Selection module</span> achieves a far higher accuracy (95% accuracy using our AI modules) than presenting a customer with lots of options to choose from (only 51% accuracy). This means no need for callbacks to request more information and no delays for the customer accessing the services they need.
//                 </p>
//               </div>
//             </div>

//             <p className="text-[#ACACAC] leading-relaxed mb-12">
//               These are just some of the benefits we believe can be achieved using our AI products and this is just the start, as we have many more in development. We want you to play and stay, so please contact me directly if you have any questions or would like to know more.
//             </p>

//             {/* Signature Section */}
//             <div className="mb-8">
//             <p className="text-6xl text-[#00D05E] mb-6" style={{ 
//   fontFamily: '"Mr De Haviland", cursive',
//   lineHeight: '1' 
// }}>Julie</p>
//               <div className="text-[#ACACAC] space-y-1">
//                 <p>Julie Rodilosso</p>
//                 <p>Chief Executive Officer</p>
//                 <p>+44 7765543170</p>
//                 <p>julie.rodilosse@rightindem.com</p>
//               </div>
//             </div>

//             {/* Continue Button */}
//             <div className="flex justify-center">
//             <button
//   onClick={() => navigate('/dashboard')}
//   className="bg-[#00D05E]/10 backdrop-blur-md border border-[#00D05E]/30 text-white px-8 py-3 rounded-lg hover:bg-[#00D05E]/20 transition-all duration-300 text-lg font-semibold shadow-[0_4px_20px_rgba(0,208,94,0.1)] hover:shadow-[0_4px_30px_rgba(0,208,94,0.2)]"
// >
//   Enter the site
// </button>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default CEOMessage;

import AnimatedBackground from "./components/AnimatedBackground";
import { useNavigate } from "react-router-dom";
import { MessageCircle, CheckCircle } from 'lucide-react';

const CEOMessage = () => {
 const navigate = useNavigate();

 return (
   <div className="relative min-h-screen flex items-center justify-center">
     <AnimatedBackground />
     <div className="relative py-4 px-6 md:px-12 w-full max-w-7xl">
       <div className="bg-black/20 backdrop-blur-sm rounded-3xl border border-[#00D05E]/30 p-6 md:p-8 text-white">
         <h1 className="font-['Impact'] text-4xl md:text-5xl text-[#00D05E] mb-3">
           Disclaimer
         </h1>
         <p className="text-[#ACACAC] text-lg leading-relaxed mb-4">Welcome to our AI Demo Portal! Please take a moment to read this before you proceed.</p>
         <p className="text-[#ACACAC] text-lg leading-relaxed mb-4">For demonstration purposes, <span className="font-semibold text-white">we've included only a selection of causes and sub-causes, guidelines, and policies</span>. When a new client comes on board, we work closely to create a <span className="font-semibold text-white">bespoke, comprehensive claims journey</span> tailored to your specific needs.</p>
         <p className="text-[#ACACAC] text-xl font-semibold leading-relaxed mb-3">Inside the portal, you'll find:</p>
         <div className="flex gap-4 mb-4">
           <MessageCircle className="text-[#00D05E] shrink-0 mt-1" size={24} />
           <p className="text-[#ACACAC] text-lg leading-relaxed">An <span className="text-white">interactive demo</span> that lets you experience Four of our core AI modules in action.</p>
         </div>
         <p className="text-[#F93DCD] text-xl font-semibold mb-3">There is a queue to use our products: waiting time to implement is now 4 months.</p>
         <p className="text-[#ACACAC] font-semibold text-xl mb-3">Just one of the many reasons why:</p>
         <div className="flex gap-4 mb-4">
           <CheckCircle className="text-[#00D05E] shrink-0 mt-1" size={24} />
           <p className="text-[#ACACAC] text-lg leading-relaxed"><span className="text-white">Cause and Sub-Cause Selection Module:</span> Our AI achieves <span className="text-white">95% accuracy</span> in selecting causes and sub-causes—compared to just <span className="text-white">51%</span> when customers are left to choose manually. This means fewer callbacks, no delays, and a smoother experience.</p>
         </div>
         <p className="text-[#ACACAC] leading-relaxed mb-6">If you want to learn more, please contact me directly.</p>
         <div className="mb-6">
           <p className="text-6xl text-[#00D05E] mb-4" style={{ fontFamily: '"Mr De Haviland", cursive', lineHeight: '1' }}>Julie</p>
           <div className="text-[#ACACAC] space-y-0.5">
             <p>Julie Rodilosso</p>
             <p>Chief Executive Officer</p>
             <p>+44 7765543170</p>
             <p>julie.rodilosse@rightindem.com</p>
           </div>
         </div>
         <div className="flex justify-center">
           <button
             onClick={() => navigate('/dashboard')}
             className="bg-[#00D05E]/10 backdrop-blur-md border border-[#00D05E]/30 text-white px-8 py-3 rounded-lg hover:bg-[#00D05E]/20 transition-all duration-300 text-lg font-semibold shadow-[0_4px_20px_rgba(0,208,94,0.1)] hover:shadow-[0_4px_30px_rgba(0,208,94,0.2)]"
           >
             Enter the site
           </button>
         </div>
       </div>
     </div>
   </div>
 );
};

export default CEOMessage;