// src/components/LineOfBusinessSelector.js
import React from "react";
import { Dog, Factory, House, ExternalLink } from "lucide-react";

const INCIDENT_TYPES = [
  {
    id: "motor",
    label: "Motor",
    value: "Motor",
    icon: Factory,
    color: "#00D05E",
    size: 24,
    description: "121 categories",
    listUrl: "#"
  },
  {
    id: "home",
    label: "Home",
    value: "Home",
    icon: House,
    color: "#00D05E",
    size: 24,
    description: "71 categories",
    listUrl: "#"
  },
  {
    id: "pet",
    label: "Pet",
    value: "Pet",
    icon: Dog,
    color: "#00D05E",
    size: 24,
    description: "78 categories",
    listUrl: "#"
  },
];

const LineOfBusinessSelector = ({ selected, onSelect, onShowCategories, page }) => (
  <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
    {INCIDENT_TYPES.map((type) => {
      const Icon = type.icon;
      return (
        <div
          key={type.id}
          onClick={() => onSelect(type)}
          className={`relative p-4 rounded-lg cursor-pointer transition-all duration-300 ${
            selected?.id === type.id
              ? "bg-gradient-to-br from-gray-800/50 to-gray-900/50 border-2 border-[#00D05E] scale-105"
              : "bg-black/20 border border-gray-800 hover:border-[#00D05E]/50"
          } pointer-events-auto`}
        >
          <div className="flex flex-col items-center text-center gap-3">
            <div
              className={`w-12 h-12 rounded-full bg-gradient-to-br from-gray-800 to-black flex items-center justify-center ${
                selected?.id === type.id ? "animate-pulse" : ""
              }`}
            >
              <Icon size={type.size} color={type.color} />
            </div>
            <div>
              <h3 className="text-white font-semibold text-lg mb-2">{type.label}</h3>
              {page === "PolicyValidation" ? "" : (
                <a 
                  href={type.listUrl}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onShowCategories();
                  }}
                  className="text-[#00D05E] inline-flex items-center gap-1 pointer-events-auto z-10 relative"
                >
                  {type.description}
                  <ExternalLink size={16} />
                </a>
              )}
            </div>
          </div>

          {/* Selected checkmark */}
          {selected?.id === type.id && (
            <div className="absolute -top-2 -right-2 w-6 h-6 bg-[#00D05E] rounded-full flex items-center justify-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4 text-white"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
          )}

          {/* Animated border for selected item */}
          {selected?.id === type.id && (
            <div className="absolute inset-0 rounded-lg border-2 border-[#00D05E] opacity-50 animate-pulse" />
          )}
        </div>
      );
    })}
  </div>
);

export default LineOfBusinessSelector;