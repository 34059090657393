import React from "react";
import ReactDOM from "react-dom/client";
import "./styles.css";
import App from "./App";
import {KindeProvider} from "@kinde-oss/kinde-auth-react";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
<React.StrictMode>
    {/* <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: process.env.REACT_APP_AUTH0_AUDIENCE, // Replace with your API identifier
        scope: 'openid profile email' // Add the scopes you need
      }}
    >
      <App />
    </Auth0Provider> */}
<KindeProvider
    domain={process.env.REACT_APP_AUTH0_DOMAIN}
    clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
    logoutUri={window.location.origin}
    redirectUri={window.location.origin}
    audience={process.env.REACT_APP_AUTH0_AUDIENCE}
    scope="openid profile email"
  >
    <App />
  </KindeProvider>,
  </React.StrictMode>
);

// import React, { useEffect } from "react";
// import ReactDOM from "react-dom/client";
// import "./styles.css";
// import App from "./App";
// import {KindeProvider, useKindeAuth} from "@kinde-oss/kinde-auth-react";
// import axios from 'axios';


// // const root = ReactDOM.createRoot(document.getElementById("root"));
// // root.render(
// // <React.StrictMode>

// // <KindeProvider
// //     domain={process.env.REACT_APP_AUTH0_DOMAIN}
// //     clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
// //     logoutUri={window.location.origin}
// //     redirectUri={window.location.origin}
// //     audience={process.env.REACT_APP_AUTH0_AUDIENCE}
// //     scope="openid profile email"
// //   >
// //     <App />
// //   </KindeProvider>,
// //   </React.StrictMode>
// // );

// const Root = () => {
//   const { getToken, login } = useKindeAuth();

//   useEffect(() => {
//     const setupAxiosInterceptors = () => {
//       axios.interceptors.response.use(
//         response => response,
//         async error => {
//           if (error.response.status === 401) {
//             try {
//               const newAccessToken = await getToken();
//               error.config.headers['Authorization'] = `Bearer ${newAccessToken}`;
//               return axios(error.config);
//             } catch (refreshError) {
//               login(); // Redirect to Kinde login
//             }
//           }
//           return Promise.reject(error);
//         }
//       );
//     };

//     setupAxiosInterceptors();
//   }, [getToken, login]);

//   return (
//     <KindeProvider
//       domain={process.env.REACT_APP_AUTH0_DOMAIN}
//       clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
//       logoutUri={window.location.origin}
//       redirectUri={window.location.origin}
//       audience={process.env.REACT_APP_AUTH0_AUDIENCE}
//       scope="openid profile email"
//     >
//       <App />
//     </KindeProvider>
//   );
// };

// const container = document.getElementById('root');
// const root = ReactDOM.createRoot(container);

// root.render(
//   <React.StrictMode>
//     <Root />
//   </React.StrictMode>
// );