import AnimatedBackground from "./components/AnimatedBackground";
import { useEffect, useRef, useState } from "react";
import Player from '@vimeo/player';
import { Volume2, VolumeX } from 'lucide-react';

const Dashboard = () => {
  const containerRef = useRef(null);
  const playerRef = useRef(null);

  useEffect(() => {
    let player = null;
    
    const initPlayer = async () => {
      if (containerRef.current) {
        const options = {
          url: 'https://player.vimeo.com/video/1029240297?h=00d34f0909',
          autoplay: true,
          loop: false,
          controls: true,
          responsive: true,
          muted: true,
          app_id: '122963',
          title: false, 
          byline: false,  
          portrait: false
        };
  
        try {
          player = new Player(containerRef.current, options);
          playerRef.current = player;
          
          await player.ready();
          await player.setVolume(0);
        } catch (error) {
          console.log("Player initialization error:", error);
        }
      }
    };
  
    initPlayer();
  
    return () => {
      if (player) {
        player.unload().catch(console.log);
        playerRef.current = null;
      }
    };
  }, []);

  return (
    <div className="relative">
      <AnimatedBackground />
      <section className="relative min-h-screen flex flex-col justify-center md:p-12 p-4">
        <div className="max-w-5xl mx-auto text-white mt-16 md:mt-0"> {/* Added margin-top for mobile */}
          <h1 className="font-['Impact'] text-[24px] md:text-[40px] text-[#00D05E] mb-2 md:mb-[23px] text-center mt-8 md:mt-0">
            {/* Added margin-top for mobile */}
            FOR YOUR EYES ONLY
          </h1>
          <h1 className="text-xl font-semibold text-[#F93DCD] mb-2">
            For this demo, we have selected a limited number of guidelines, categories and policies. 
            You can have as many as you want when you decide to go with us. 
            This is purely for demonstration only.
            </h1>
          <p className="leading-[160%] mb-[24px] text-[#ACACAC] text-left">
            Welcome to Optium your 24/7 AI claims assistant. Optium is
            made of individual modules so you can see how it helps you and your
            policyholders. Select the module you want to see, learn about what
            it does, and take the demos to experience its power for yourself.
          </p>
          {/* Video Container */}
          <div className="relative w-full aspect-video rounded-xl border border-gray-800 overflow-hidden mb-12 bg-black/40">
            <div 
              ref={containerRef}
              className="w-full h-full z-10 relative pointer-events-auto"
              style={{
                position: 'relative',
                zIndex: 50
              }}
            />
          </div>

          <div className="text-left">
            <h2 className="font-['Impact'] text-2xl text-[#00D05E] mb-2">
              MODULES SECTION
            </h2>
            <p className="leading-[160%] mb-[38px] text-[#ACACAC]">
              The Modules section provides an overview of the various AI models
              available to enhance your processes. Each module is designed to
              address specific challenges, offering unique capabilities to
              improve efficiency and accuracy. This section allows you to
              explore the functionalities of each module, understand how they
              can be integrated into your workflow, and see the potential
              benefits they offer.
            </p>
            <h2 className="font-['Impact'] text-2xl text-[#00D05E] mb-2">
              USE-CASES SECTION
            </h2>
            <p className="leading-[160%] mb-[38px] text-[#ACACAC]">
              The Use-Cases section showcases practical applications of our AI
              solutions in real-world scenarios. Here, you can find examples of
              how our technology can be implemented to solve common industry
              challenges. This section helps you visualise the impact of our AI
              tools, providing insights into how they can be tailored to meet
              your specific needs and drive tangible result
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Dashboard;