// components/ExtractAnimation.js
import React from "react";
import { FileSearch } from "lucide-react";

const ExtractAnimation = ({label = "Extracting Policy Information"}) => (
  <div className="absolute inset-0 z-50 flex items-center justify-center bg-black/50 backdrop-blur-sm">
    <div className="absolute inset-0 rounded-xl p-8 flex flex-col items-center gap-4 w-[80%] mx-auto">
      {/* Adjusted to be wider and shorter */}
      <div className="relative w-full aspect-[16/9] bg-gray-900 rounded-lg overflow-hidden">
        <div className="absolute inset-x-0 h-2 bg-[#00D05E]/50 animate-scan z-50" />
        <div className="absolute inset-0 flex items-end justify-center bottom-4">
          <FileSearch size={48} className="text-[#F93DCD] z-[100]" />
        </div>
        <div className="absolute inset-0 flex flex-col gap-2 p-4">
          {[...Array(6)].map((_, i) => (
            <div
              key={i}
              className="h-2 bg-gray-800 rounded-full"
              style={{ width: `${Math.random() * 40 + 60}%` }}
            />
          ))}
        </div>
      </div>

      <p className="text-xl text-white">{label}</p>

      <div className="flex gap-2">
        <span
          className="w-2 h-2 rounded-full bg-[#00D05E] animate-bounce"
          style={{ animationDelay: "0ms" }}
        />
        <span
          className="w-2 h-2 rounded-full bg-[#00D05E] animate-bounce"
          style={{ animationDelay: "150ms" }}
        />
        <span
          className="w-2 h-2 rounded-full bg-[#00D05E] animate-bounce"
          style={{ animationDelay: "300ms" }}
        />
      </div>
    </div>
  </div>
);

export default ExtractAnimation;
