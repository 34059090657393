import React from 'react';
import { X } from 'lucide-react';

const CategoryModal = ({ isOpen, onClose, categories, type }) => {
  if (!isOpen) return null;

  const getTitle = (selectedType) => {
    // Get title based on the type of the category being viewed, not the selected business
    const titles = {
      motor: 'Motor Incident Categories',
      home: 'Home Incident Categories',
      pet: 'Pet Incident Categories'
    };
    return titles[selectedType] || 'Categories';
  };

  const renderCategories = (categoryObj) => {
    return Object.entries(categoryObj).map(([mainCategory, subCategories]) => {
      if (mainCategory === "Unlisted Incident") return null;
      
      return (
        <div key={mainCategory} className="mb-6">
          <h3 className="text-[#00D05E] text-lg font-semibold mb-2">{mainCategory}</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {Object.entries(subCategories).map(([subCategory, items]) => (
              <div key={subCategory} className="bg-black/40 rounded-xl p-4 border border-gray-800">
                <h4 className="text-[#F93DCD] font-medium mb-2">{subCategory}</h4>
                <ul className="space-y-1">
                  {items.map((item, index) => (
                    <li key={index} className="text-[#ACACAC] text-sm flex items-center">
                      <span className="w-1.5 h-1.5 bg-[#00D05E] rounded-full mr-2" />
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      );
    });
  };

  return (
    <div 
      className="fixed inset-0 z-50 flex items-center justify-center p-4"
      onClick={onClose}
    >
      {/* Backdrop with animation */}
      <div 
        className="absolute inset-0 bg-black/80 backdrop-blur-sm"
        style={{
          animation: 'fadeIn 0.3s ease-out'
        }}
      />
      
      {/* Modal Content with animation */}
      <div 
        className="relative w-full max-w-5xl max-h-[80vh] bg-black/95 rounded-3xl border border-gray-800 p-6 overflow-hidden"
        onClick={e => e.stopPropagation()}
        style={{
          animation: 'modalEnter 0.3s ease-out'
        }}
      >
        <button
          onClick={onClose}
          className="absolute top-4 right-4 p-2 text-gray-400 hover:text-white transition-colors"
        >
          <X size={24} />
        </button>
        
        {/* <h2 className="text-2xl font-bold text-[#00D05E] mb-6">
          {getTitle(type)}
        </h2> */}
        
        <div className="overflow-y-auto max-h-[calc(80vh-120px)] pr-2 custom-scrollbar">
          {renderCategories(categories)}
        </div>
      </div>
    </div>
  );
};

export default CategoryModal;