import CryptoJS from 'crypto-js';

export const decryptResponse = (encryptedData) => {
  try {
    const publicKey = process.env.REACT_APP_ENCRYPTION_PUBLIC_KEY;
    
    if (!encryptedData || !publicKey) {
      throw new Error('Missing encrypted data or public key');
    }

    // Decode the Base64 string to get the IV + encrypted data
    const cipherData = CryptoJS.enc.Base64.parse(encryptedData);
    
    // Extract IV (first 16 bytes) and encrypted data
    const iv = CryptoJS.lib.WordArray.create(cipherData.words.slice(0, 4));
    const encrypted = CryptoJS.lib.WordArray.create(cipherData.words.slice(4));
    
    // Decrypt using the key and IV
    const decrypted = CryptoJS.AES.decrypt(
      { ciphertext: encrypted },
      CryptoJS.enc.Base64.parse(publicKey),
      {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      }
    );

    const decryptedText = decrypted.toString(CryptoJS.enc.Utf8);
    return JSON.parse(decryptedText);
  } catch (error) {
    console.error('Decryption failed:', error);
    return null;
  }
};