import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import MainLayout from "./MainLayout";
import GuidedFreeText from "./GuidedFreeText";
import HierarchySelection from "./HierarchySelection";
import InconsistencyIdentification from "./InconsistencyIdentification";
// import TestModule from './TestModule';
import PolicyExtraction from "./PolicyExtraction";
import CEOMessage from "./CEOMessage";
// import WebAnalytics from './components/WebAnalytics';
import Dashboard from "./Dashboard";
import PolicyValidation from "./PolicyValidation";
import CauseSubCauseSelection from "./CauseSubCauseSelection";
import HomePage from "./HomePage";
import ProtectedRoute from "./components/ProtectedRoute";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
// require('newrelic');

function App() {
  const { isAuthenticated, isLoading } = useKindeAuth();

  if (isLoading) {
    return null; // Or a loading spinner
  }

  return (
    <Router>
      {/* <WebAnalytics> */}
        <Routes>
        <Route 
  path="/welcome" 
  element={
    isAuthenticated ? 
      <CEOMessage /> : 
      <Navigate to="/" replace />
  } 
/>
          {/* Redirect authenticated users from home to dashboard */}
          <Route 
            path="/" index
            element={
              isAuthenticated ? 
                <Navigate to="/welcome" replace /> : 
                <HomePage />
            } 
          />
          
          <Route
            path="/dashboard"
            element={
                <MainLayout />
            }
          >
            <Route index element={<Dashboard />} />
            <Route path="category-selection" element={<HierarchySelection />} />
            {/* <Route path="test-module" element={<TestModule />} /> */}
            <Route path="guided-text" element={<GuidedFreeText />} />
            <Route
              path="inconsistency"
              element={<InconsistencyIdentification />}
            />
            <Route path="policy-extraction" element={<PolicyExtraction />} />
            <Route path="policy-validation" element={<PolicyValidation />} />
            <Route
              path="cause-sub-cause-selection"
              element={<CauseSubCauseSelection />}
            />
          </Route>

          {/* Catch all route for unknown paths */}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      {/* </WebAnalytics> */}
    </Router>
  );
}

export default App;
