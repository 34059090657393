// import { useEffect, useState } from 'react';
// import { useKindeAuth } from "@kinde-oss/kinde-auth-react";

// const useAccessToken = () => {
//   const [accessToken, setAccessToken] = useState('');
//   const { getToken, isAuthenticated} = useKindeAuth();

//   useEffect(() => {
//     const getTokenKinde = async () => {
//       if (isAuthenticated) {
//         try {
//           // const token = await getAccessTokenSilently({cacheMode: "off"});
//           const token = await getToken();
//           setAccessToken(token);
//         } catch (error) {
//           console.error('Error getting access token', error);
//         }
//       }
//     };

//     getTokenKinde();
//   }, [isAuthenticated, getToken]);

//   return accessToken;
// };

// export default useAccessToken;

import { useEffect, useState } from 'react';
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

const useAccessToken = () => {
  const [accessToken, setAccessToken] = useState(() => Cookies.get('kinde_token') || '');
  const { getToken, login, logout } = useKindeAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const getTokenKinde = async () => {
      try {
        const token = await getToken();
        if (token) {
          Cookies.set('kinde_token', token, { 
            secure: true, 
            sameSite: 'strict',
            expires: 1 // 1 day, adjust as needed
          });
          setAccessToken(token);
        } else {
          Cookies.remove('kinde_token');
          setAccessToken('');
          navigate('/login');
        }
      } catch (error) {
        console.error('Error fetching access token:', error);
        Cookies.remove('kinde_token');
        setAccessToken('');
        navigate('/login');
      }
    };

    getTokenKinde();

    return () => {
      if (logout) {
        Cookies.remove('kinde_token');
      }
    };
  }, [getToken, logout, login, navigate]);

  return accessToken;
};

export default useAccessToken;