import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { Menu } from "lucide-react";
import { useState } from "react";

const TopNav = ({ toggleMobileNav }) => {
  const [isMenuHidden, setIsMenuHidden] = useState(true);
  const { logout, user } = useKindeAuth();

  return (
    <div className="absolute top-4 left-4 right-4 z-50 text-white"> {/* removed h-full */}
      <div className="flex justify-between items-center">
        <div>
          <button className="md:hidden" onClick={toggleMobileNav}>
            <Menu size={24} />
          </button>
        </div>
        <div>
          <div className="md:hidden">
            <span className="block text-center bg-gradient-to-r from-white to-[#999999] text-transparent bg-clip-text text-[8.19px] font-semibold leading-none">
              Welcome to
            </span>
            <span className="block text-center bg-gradient-to-r from-white to-[#999999] text-transparent bg-clip-text text-[21.84px] font-bold leading-tight">
              Optium
            </span>
            <span className="block text-center bg-gradient-to-r from-white to-[#999999] text-transparent bg-clip-text text-[8.19px] font-semibold leading-none">
              Your Claims Assistant
            </span>
          </div>
        </div>
        <div className="relative">
          <img
            className="w-8 h-8 rounded-full"
            src={"/dummy_user_image.png"}
            alt="User"
            onClick={() => setIsMenuHidden((prevState) => !prevState)}
          />
          {!isMenuHidden && (
            <div className="bg-white rounded-xl absolute top-12 right-2 z-50 px-4 py-2 opacity-0 animate-fade-in">
              <button className="text-black" onClick={logout}>
                Logout
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TopNav;
